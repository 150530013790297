class WhatsappButtonTracking {
  constructor() {
    this.navbarIcon = document.querySelector('.wa-icon');
  }
  init() {
    this.navbarIcon.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();

      dataLayer.push({
        event: 'whatsapp_message',
        location: 'navbar'
      });

      window.open(this.navbarIcon.href);
    })
  }
}

export default WhatsappButtonTracking;
