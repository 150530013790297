class NavDropdown {
  constructor() {
    this.navDropdownActivators = document.querySelectorAll('.dropdown-toggle-activator');
    this.navDropdownContainers = document.querySelectorAll('.dropdown-toggle');
  }

  init() {
    for (let i = 0; i < this.navDropdownActivators.length; i++) {
      const dropdownToggleEl = this.navDropdownActivators[i];

      dropdownToggleEl.addEventListener('mouseenter', (e) => {
        const dropdownSubmenu = dropdownToggleEl.nextElementSibling;

        dropdownSubmenu.classList.add('active');

        setTimeout(() => {
          dropdownSubmenu.classList.add('visible');
        }, 50);
      });
    }

    for (let i = 0; i < this.navDropdownContainers.length; i++) {
      const navDropdownContainer = this.navDropdownContainers[i];

      navDropdownContainer.addEventListener('mouseleave', (e) => {
        const dropdownSubmenu = e.target.querySelector('.dropdown-items-container');

        dropdownSubmenu.classList.remove('visible');

        setTimeout(() => {
          dropdownSubmenu.classList.remove('active');
        }, 300);
      });
    }
  }
}

export default NavDropdown;
