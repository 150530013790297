import ActiveLinkHighlight from './activeLinkHighlight';
import MobileNav from './mobileNav';
import NavDropdown from './navDropdown';
import WhatsappButtonTracking from './whatsappButtonTracking';

class GlobalScripts {
  constructor() {
    new MobileNav();
    new ActiveLinkHighlight();
    new NavDropdown().init();
    new WhatsappButtonTracking().init();
  }
}

export default GlobalScripts;
