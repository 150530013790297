class ActiveLinkHighlight {
  constructor() {
    this.mobileNav = document.querySelector('#mobile-nav');
    this.navbar = document.querySelector('.navigation');

    const elements = document.querySelectorAll('.navigation__right a, #mobile-nav ul a');

    for (const element of elements) {
      const strippedPath = element.href.replace(location.origin, '');

      if (strippedPath === location.pathname) {
        element.classList.add('active');

        const closestDropdownToggle = element.closest('.dropdown-toggle')

        if (closestDropdownToggle) {
          closestDropdownToggle.classList.add('active');
        }
      } else {
        element.classList.remove('active');
      }
    }
  }
}

export default ActiveLinkHighlight;
