class MobileNav {
  constructor() {
    this.modalNavigationContainer = document.getElementById("mobile-nav");
    this.mobileNavTrigger = document.querySelector('.mobile-nav-trigger');
    this.mobileNavDropdownToggles = document.querySelectorAll('.mobile-dropdown-toggle');

    if (this.mobileNavTrigger) {
      this.mobileNavTrigger.addEventListener("click", () => {
        this.mobileNavTrigger.classList.contains('fa-bars')
          ? this.openSubnav()
          : this.closeSubnav();
      });
    }

    for (let i = 0; i < this.mobileNavDropdownToggles.length; i++) {
      const mobileNavDropdownToggle = this.mobileNavDropdownToggles[i];

      mobileNavDropdownToggle.addEventListener('click', (e) => {
        const dropdownListContainer = mobileNavDropdownToggle.nextElementSibling;

        const dropdownClassList = dropdownListContainer.classList;

        dropdownClassList.toggle('active');
      });
    }
  }

  openSubnav() {
    this.modalNavigationContainer.classList.add("active");
    this.mobileNavTrigger.classList.remove('fa-bars');
    this.mobileNavTrigger.classList.add('fa-xmark');
  }

  closeSubnav() {
    this.modalNavigationContainer.classList.remove("active");
    this.mobileNavTrigger.classList.add('fa-bars');
    this.mobileNavTrigger.classList.remove('fa-xmark');
  }
}

export default MobileNav;
