import '../../styles/index.scss';
import './index.scss';

import Splide from '@splidejs/splide';

new Splide('.splide', {
  pagination: false
}).mount();

import GlobalScripts from '../../scripts/global';

new GlobalScripts()